/* styles.module.css */

.treatmentsDisplay { 
  border-right: 1px solid #dee2e6; /* Right border for separation */
  padding: 20px;
  height: 100%; /* Take full height */
  min-height: 260px;
}

.treatmentsDisplayHeading{
  text-align: center;
  margin-bottom: 20px;
}

.treatmentsDisplayBox{
  max-height: 600px;
  overflow-y: auto; /* For scrolling */
}

.details{
  display: flex;
  flex-direction: column;
}

.listItem {
  padding: 15px 20px; /* Adjusted padding for better spacing */
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 5px; 
  width: 100%;

  &:hover {
    background-color: #e2e6ea;
  }
}

.listItem:last-child {
  border-bottom: none;
}

.listItem {
  padding-left: 5px !important;
}

.listItem.withTreatmentNotes {
  background-color: #f0fff4; /* Subtle green */
  border-left: 5px solid #64dd17; /* Add a border on the left for visual indication */
}

.listItem.withOrderedNotes {
  background-color: #fef6e9; /* Subtle green */
  border-left: 5px solid #ffd580; /* Add a border on the left for visual indication */
}

.importRow{
  width: 100%;
}