@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@media print {


  .navbar{
    visibility: hidden;
  }
  .print-header, .print-header * {
      visibility: visible;
  }

  .print-page, .print-page * {
      visibility: visible;
  }

  .print-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid black;
      font-size: small;
      padding: 10px;
      background: white;
  }

  .signature-style {
    font-family: 'Great Vibes', cursive;
    font-size: 24px;
  }

  .print-page {
    page-break-after: always;
  }

  .print-content {
      margin-top: 220px; /* Adjust this value based on your header's height */
  }

  @page {
      size: letter;
      margin: 20mm 10mm;
  }
}
