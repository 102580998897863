.react-calendar{
    border-radius: 8px;
    color:#FFFFFF;
    background-color: #292D32 !important;
    padding:22px;
    width:100%;
    
}

abbr[title] {
    text-decoration: none;
    font-size: 10px;
    font-weight: 100;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-align: center;
    color: #B5BEC6;
    
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus{
    background-color: #F04D23;
    border-radius:50%;
}

.react-calendar__tile--active{
    background-color: #F04D23;
    border-radius:50%;
}

.react-calendar__tile--now{
    background-color: #ff2d55 !important;
    border-radius:50%;
}

.react-calendar__navigation{
    margin-top: -1px;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
}

.react-calendar__month-view__weekdays{
    margin-top: -10px;
    margin-bottom: 12px;
}

.react-calendar__tile{
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;

}