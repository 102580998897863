/* styles.module.css */

.modalContainer {
    padding: 20px;
    background: #f0f2f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.modalTitle {
    color: #0056b3;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.listContainer {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    margin-bottom: 10px;
    background-color: #f7f7f7;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.listItem:hover {
    background-color: #e6e6e6;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.checkbox {
    flex-grow: 1;
    margin-right: 12px;
}

.customTemplateSelect {
    margin-top: 16px;
    .ant-select-selector {
        border-radius: 5px !important;
        border: 1px solid #ced4da !important;
        transition: border-color 0.3s ease;
    }
}

.customTemplateSelect .ant-select-selector:hover {
    border-color: #adb5bd !important;
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.footerButton {
    width: 48%;
    font-weight: 600;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.applyButton {
    background-color: #28a745;
    color: #28a745;
    border-color: #28a745;
}

.applyButton:hover {  
    background-color: #218838 !important;
    border-color: #1e7e34 !important; 
    color: white !important;
}

.applyButton:focus {  
    background-color: #218838 !important;
    border-color: #1e7e34 !important; 
    color: white !important;
}

.cancelButton {
    background-color: #dc3545;
    color: #dc3545;
    border-color: #dc3545;
}

.cancelButton:hover {  
    background-color: #c82333 !important;
    border-color: #bd2130 !important;
    color: white !important;
}

.cancelButton:focus {  
    background-color: #c82333 !important;
    border-color: #bd2130 !important;
    color: white !important;
}
