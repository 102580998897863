.Headingblock-Text h3,.UserRoll-Block h2,
.UserRoll-Block h6 {
    font-family: "Open Sans", sans-serif;
    margin: 0px;
}

.Headingblock-Text h3 {
  font-family: 'Raleway', sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;

}

.header-flexbox {
  height: 89px;
  border: 0px, 0px, 1px, 0px;
  padding: 1.563vw 6.25vw 1.563vw 6.25vw;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DDDDDD;

}

.UserRoll-Block h2
{
    font-size: 1.167vw;
}
.UserRoll-Block h6
{
    font-size: 0.867vw;
    font-style: italic;
    color: #626161;
    font-weight: 500;
    
}

.UserRoll-Block {
  border-radius: 41px;
  height: 47px;
  width:283px;
  background-color: #1C82F5;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.11);
  backdrop-filter: blur(19.5px);
  display: flex;
  padding: 5px;
  gap: 1.1vw;;
  align-items: center;
}

.username-userRoll{
    display: flex;
    flex-direction: row;
    gap: 2.5vw;
}

.username-userRoll h2{
  font-family: 'Raleway', sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  color:white;
}

.username-userRoll h6{
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: right;
  color:#DADADA;


}

.circle-username {
  display: flex; /* or grid */
  justify-content: center; /* For horizontal centering */
  align-items: center; /* For vertical centering */
  background-color: white;
  border-radius: 50%;
  max-width: 39px;
  min-width: 32px;
  min-height: 32px;
  width: auto;
  height: auto;
  max-height: 39px;
}

.circle-username h2 {
  color: #1C82F5;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin: 0; /* Remove default margin */
}
