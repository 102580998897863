.login_box {
  box-sizing: border-box; /* This will include padding and border in the total width/height */
  width: 400px;
  padding: 20px;
  height: auto; /* This will allow the box to grow based on content */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.img1 {
  max-width: 100%;
}

.login_header {
  margin-bottom: 30px;
  color: #000;
  text-align: center;
  font-family: "Urbanist", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 82% */
  letter-spacing: -0.32px;
}

.login_input {
  box-sizing: border-box; /* Same reason as above */
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #f4f7fe;
  color: #000000;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Mazzard", sans-serif;
}

.login_input:focus {
  border-color: none !important;
}

.login_btn {
  box-sizing: border-box; /* This ensures padding is included in total width */
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 38px;
  color: #fff;
  text-align: center;
  font-family: "Mazzard", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 80.769% */
  letter-spacing: -0.32px;
  text-transform: uppercase;
  background: linear-gradient(
    92deg,
    #1fa0cb 0.42%,
    #5fbbda 36.51%,
    #70c186 61.05%,
    #8cd663 88.48%
  );
}

.login_btn:hover {
  background: linear-gradient(
    272deg,
    #1fa0cb 0.61%,
    #5fbbda 38.91%,
    #70c186 64.95%,
    #8cd663 94.06%
  );
}

.login_signup {
  margin-top: 10%;
  color: #1c82f5;
  font-family: "Mazzard", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
  letter-spacing: -0.408px;
  display: flex;
  justify-content: center;
}

.login_signup_highlight {
  color: #000;
  font-family: "Mazzard", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.408px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.login_signup_highlight:hover {
  color: #1c82f5; /* Slightly darker shade for hover effect */
}

.error_message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.logo {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.loginCotainerLoginView {
  background-image: url("../../Assets/view-old-tree-lake-with-snow-covered-mountains-cloudy-day.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}