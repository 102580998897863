
.ant-table-wrapper .ant-table-thead >tr>th{
    background-color: #E9E9E9;
    border: 1px solid white;
    padding: 15px 20px;
}
.labs-column .ant-table-cell {
    min-width: 0 !important;
    max-width: 300px !important;
  }
  
  /* Targeting the cells, if needed */
  .labs-column-cell {
    min-width: 0 !important;
    max-width: 300px !important;
  }

td.ant-table-cell{
    font-family: "Raleway, sans-serif" !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}