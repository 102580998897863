.headerFlexbox {
    height: 89px;
    border: 0px, 0px, 1px, 0px;
    padding: 1.563vw 6.25vw 1.563vw 6.25vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
}

.exportHeading {
    font-family: 'Raleway', sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
    text-align: left;
}

.UpdationBlock {
    backdrop-filter: blur(19.5px);
    background-color: #F3F3F3;
    margin-bottom: 20px;
    height: 132px;
    width: 87%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
  
.UpdationBlock .blockTwo{
    font-family: 'Raleway', sans-serif;
    font-size: 23px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color:black
}

.UpdationBlock .colorBox{
    height: 100%;
    width:20px;
}
  