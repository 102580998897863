.dropdown {
  width: 100%;
  margin-bottom: 5px;
}

.input {
  width: 100%;
}

.prepviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px !important;
  margin-bottom: 50px;
}

.prepviewHeader h1 {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 55% */
  letter-spacing: -0.408px;
}

.dateSelectAndDisplay {
  background-color: transparent;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.dateDisplay {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dateDisplay_flexPrep {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.columnSelectionButton {
  margin-bottom: 16px;
}

.container {
  padding: 2rem;
  /* background-color: #f6f6f6;
  background-image: url(../../Assets/view-old-tree-lake-with-snow-covered-mountains-cloudy-day.jpg); */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 4rem;
}

.tableContainer {
  overflow-x: hidden;
  margin-top: 20px;
  border-radius: 5px;
  max-width: 95vw;
}

.tableContainerHeader {
  margin-top: 0;
}

.input {
  width: 200px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
}

.checkbox {
  margin: 0;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-xu9wm8).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background-color: #068aec !important;
  color: white !important;
}

.patientInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  border-radius: 8px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* Styling for the patient select container */
.patientSelectContainer {
  flex: 1;
  background: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
}

.patientSelectContainer h3 {
  color: #333;
  margin-bottom: 15px;
}

.Select {
  width: 100%;
  margin-bottom: 10px;
}

.InactiveCheckContainer {
  display: inline;
  background-color: white;
  padding: 5px;
  border-radius: 15px;
  margin-left: 5px;
}

.InactiveCheck {
  cursor: pointer;
}

.InactiveCheckHeading {
  display: inline;
  cursor: default;
}

.patientDetailsContainer {
  padding: 0.99vw 1.563vw;
  flex: 1; /* To ensure it takes the remaining space next to patientSelectContainer */
  max-width: 100%; /* Adjusted maximum width to take half of the parent container width minus the gap */
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 22px 0px #00000024;
  height: 380px;
}

/* Styles for individual editable fields */
.editableField {
  margin-bottom: 0; /* Remove margin-bottom to handle spacing with Row gutter */
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.editableField strong {
  display: block;
  color: #333;
  margin-bottom: 5px;
}

.editableField .ant-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.editableField input:focus,
.editableField .ant-picker:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.editableField input {
  background-color: #f2f2f2;
  font-size: 18px;
  height: 44px;
  border-radius: 6px;
}

.checkboxLabel {
  display: flex;
  align-items: center; /* This ensures vertical alignment */
  gap: 10px;
  padding: 0px !important;
}

.checkboxLabel strong {
  margin-bottom: 0; /* Remove margin bottom from the strong tag */
}

.checkboxStyle[type="checkbox"] {
  accent-color: #007bff;
  border: 1px solid #1c82f5;
  border-radius: 6px;
  margin-top: -2px !important;
}

.detailsRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Styles to ensure the checkbox is on its own line */
.InactiveCheckContainer {
  display: block; /* or 'flex' if you want to keep the internal flex properties */
  margin-top: 10px; /* Adjust as needed */
}

@media (max-width: 768px) {
  .patientInfo {
    flex-direction: column;
  }

  .patientSelectContainer,
  .patientDetailsContainer {
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 100%;
  }
  .detailsRow {
    flex-direction: column;
  }
}
.selected_patient_contentTextPrep {
  color: #000;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}
.patientContentPlaceholder {
  color: #a5a5a5;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.patientInformtaionFlexPrep {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.roomAndAssignee_Prep {
  display: flex;
  gap: 1vw;
  margin-top: 8px;
}

.tables-treatments-prep {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.tables-treatments-Note {
  width: 100%;
}

.editableField {
  display: flex;
  flex-direction: column;
}

.patientContentPlaceholder {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
}
