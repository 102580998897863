.exportHeading {
  font-family: "Raleway", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
}
.formHeading {
  font-family: "Raleway", sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
}
.headerFlexbox {
  height: 89px;
  border: 0px, 0px, 1px, 0px;
  padding: 1.563vw 6.25vw 1.563vw 6.25vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dddddd;
}
