/* Main COnatiner */
.container {
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 6px;
  width: 100%;
}

.todayViewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  font-size: 2rem; /* Responsive font size */
  font-weight: 600; /* Less bold than bold */
  margin-bottom: 20px; /* More space below the heading */
  color: #333; /* Dark grey color */
  text-transform: uppercase; /* Stylistic choice */
  letter-spacing: 1px; /* Space out letters */
}

.tableContainer {
  box-shadow: 0px 4px 22px 0px #00000024;
  width: 100%;
  background: #ffffff;
  border-radius: 6px; /* Larger border radius */
  padding: 30px; /* More padding for a spacious look */
  overflow-x: auto; /* Ensures horizontal scroll for overflow */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for shadow */
  margin-bottom: 2vw;
}

.tableContainer:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Larger shadow on hover */
}

.apply-btn {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.scrollableCheckboxGroup {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd; /* Add border to the checkbox group */
  border-radius: 8px; /* Match the border-radius of the container */
  padding: 10px; /* Spacing within the checkbox group */
}

.todayViewWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Consistent shadow with the tableContainer */
  background-color: #ffffff; /* Match the table container background */
  padding: 20px;
  border-radius: 8px; /* Consistency in design */
  transition: transform 0.3s ease-in-out; /* Smooth transition for any position changes */

  @media (max-width: 600px) {
    width: 100%;
  }
}

.todayViewWrapper:hover {
  transform: translateY(-5px); /* Slight raise effect on hover */
}

/* Custom scrollbar for scrollable elements */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Responsive Typography */
@media (max-width: 768px) {
  .heading {
    font-size: 1.5rem; /* Smaller font size on smaller screens */
  }
}

.patientButtonsGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Creates a grid with 6 columns */
  gap: 10px; /* Adjust the gap between buttons as needed */

  padding: 30px;
}

.patientButton {
  padding: 10px; /* larger padding for a bigger button */
  border: none;
  border-radius: 8px; /* slightly larger border radius for aesthetic */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-size: 0.9rem; /* adjust font size as necessary */
  color: #333; /* darker text for better contrast */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
}

.patientButton:hover {
  transform: scale(1.05); /* subtle grow effect on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* deeper shadow on hover */
}

/* Define light colors */
.lightColors {
  --lightColor1: #f8d7da;
  --lightColor2: #d1ecf1;
  --lightColor3: #d4edda;
  --lightColor4: #fff3cd;
  --lightColor5: #fce8b2;
  --lightColor6: #dcd3ff;
}

.patientRoomBtnFlexBox {
  display: flex;
  gap: 1vw;
}

.patientInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;
  max-width: 90%;
}

.patientSelectContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;
}

.dateDisplay {
  margin: 0 10px;
  font-size: 18px;
  font-weight: 600;
}

.calendarContainer {
  width: 333px !important;
  height: 313px;
  border-radius: 8px;
  gap: 22px;
  justify-content: center;
  color: #222;
  line-height: 1.125em;
  position: relative; /* Ensure this is positioned relative or absolute as needed */
  z-index: 1000; /* Adjust z-index as necessary to bring it to the front */
  background: white; /* Ensure the background is not transparent */
  /* Add drop shadow or other styling as needed for aesthetics */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @media (max-width: 600px) {
    margin-left: 20px !important;
    width: 300px;
  }
}

.dropdownList {
  position: absolute !important;
  background-color: white; /* Solid white background */
  /* Add additional styling as needed, like width, border, etc. */
}

Table {
  font-family: "Raleway", sans-serif;
}

/* Calender styling */

.patientInfoContainer {
  row-gap: 10px;
}

.dateQuickSelectButton {
  background-color: #46dc69; /* Green background */
  color: white; /* White text */
  border: none; /* No border */
  padding: 0 10px; /* Padding for button */
  text-align: center; /* Center text */
  text-decoration: none; /* No underline */
  display: inline-block; /* Inline display */
  font-size: 16px; /* Font size */
  margin: 0 2px; /* Margin around buttons */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.dateQuickSelectButton:hover {
  background-color: rgb(40, 207, 79); /* Darker green on hover */
  color: white !important; /* White text */
}

.floatingRoomsList {
  background-color: white;
  width: 333px !important;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  transition: max-height 0.3s ease-in-out; /* Transition for collapsing */
  max-height: 300px;
  border: 1px solid #1c82f5;
  color: #1c82f5;
}

.filter-options {
  font-family: "Raleway", sans-serif !important;
}

.roomsListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.roomsListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.floatingRoomsList h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
  margin-right: 5px;
  color: #1c82f5;
}

/* Style when the list is closed */
.floatingRoomsList.closed {
  max-height: 40px; /* Height just enough to show the header */
  overflow: hidden;
}

.floatingRoomsList ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.floatingRoomsList li {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333; /* Darker text for visibility */
}

/* Tooltip styles */
.customTooltip .ant-tooltip-inner {
  background-color: #fff; /* White background */
  color: #333; /* Dark text for better readability */
  font-size: 0.85rem; /* Adjust the font size as needed */
  border-radius: 4px; /* Rounded corners for modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 10px; /* Padding inside the tooltip */
  max-width: 250px; /* Maximum width of the tooltip */
  border: 1px solid #e8e8e8; /* Light border for definition */
}

.customTooltip .ant-tooltip-arrow {
  border-color: #e8e8e8; /* Arrow border color */
}

/* Styling for the content sections inside the tooltip */
.customTooltip .tooltipSection {
  margin-bottom: 8px; /* Space between sections */
}

.customTooltip .tooltipSection:last-child {
  margin-bottom: 0; /* No bottom margin for the last section */
}

.customTooltip .tooltipSection strong {
  display: block; /* Make the section title block level */
  margin-bottom: 4px; /* Space between title and items */
  color: #000; /* Darker color for section titles */
  font-weight: 600; /* Slightly bolder font for titles */
}

.customTooltip .tooltipItem {
  padding-left: 10px; /* Padding for list items */
  position: relative; /* Position relative for custom list item styling */
}

.customTooltip .tooltipItem:before {
  content: ""; /* Pseudo-element for custom bullet */
  width: 4px; /* Size of the bullet */
  height: 4px; /* Size of the bullet */
  background-color: #666; /* Bullet color */
  border-radius: 50%; /* Round bullet */
  position: absolute; /* Absolute position */
  left: 0; /* Align to the left */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
}

.droppableContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  gap: 0.88vw;
}

.draggableCardDisplay {
  width: calc(
    33.333% - 10px
  ); /* Adjust width for 3 cards per row, accounting for gap */
  overflow: hidden;
  background-color: #f0f4f7;
  border-radius: 8px !important;
  border: none;
  color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.draggableCardDisplay:hover {
  background-color: #e2e6ea; /* Slightly darker background on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer; /* Change cursor to indicate clickable */
}

.selectedRow {
  background-color: orange !important;
}

.scheduledRow {
  background-image: linear-gradient(
    to right,
    #89cff0 0%,
    #89cff0 6px,
    transparent 4px
  );
}

.arrivedRow {
  background-image: linear-gradient(
    to right,
    orange 0%,
    orange 6px,
    transparent 4px
  );
}

.pickupRow {
  background-image: linear-gradient(
    to right,
    #f6c4f8 0%,
    #f6c4f8 4px,
    #f6c4f8 6px,
    #f6c4f8 100%
  );
}

.completedRow {
  background-image: linear-gradient(
    to right,
    #00a36c 0%,
    #00a36c 6px,
    transparent 4px
  );
}

.TableShortcutBtns {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 30px;
}

.TableShortcutBtns > Button {
  margin-right: 5px;
}

.selected-row-day-view {
  background-color: #f0f0f0; /* Your desired color */
}

.bulkActionContainer {
  display: flex;
  font-family: "Raleway", sans-serif;
}

.filtersContainer {
  display: flex;
  width: 100%;
  font-family: "Raleway", sans-serif !important;
  font-weight: 600;
}

.filter-options {
  font-family: "Raleway", sans-serif !important;
}

/* You might want to adjust the widths to fit your layout */
.filtersContainer .ant-input {
  width: 200px; /* Width for the search input */
  font-family: "Raleway", sans-serif;
}

.filtersContainer .ant-select {
  width: 150px; /* Width for the filter dropdowns */
}

.bulkActionContainer {
  display: flex;
}

.TableTopFunctionalities {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.dayViewHeading {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 55% */
  letter-spacing: -0.408px;
}

/* today view css */
.dayviewHeadinBlock {
  display: flex;
  gap: 10px;
  align-items: center;
}

.datetext {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dateflexToday {
  display: flex;
  align-items: center;
}

.dateflexRooms {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
}

.patientInfoAndCal {
  margin-top: 0px;
  /* display: flex;
  flex-direction: column;
  order: 1;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  width: 333px !important; */
}

.todayViewMidSection {
  display: flex;
  gap: 1vw;
  margin-top: 2vw;
  margin-bottom: 4vw;
}

.dragableCardsSection {
  width: 100%;
}

.calendarBubble {
  position: relative;
  margin-bottom: -14px;
  width: 25px;
  border-radius: 50%;
  background-color: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calendarBubbleGreen {
  position: relative;
  margin-bottom: -14px;
  width: 25px;
  border-radius: 50%;
  background-color: green;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dragableCardsContainer {
  width: auto;
  min-width: 20%;
  max-width: 25%;
}

.scheduledOuterDiv,
.arrivedOuterDiv,
.completedOuterDiv {
  border-radius: 5px;
  background: #89cff0;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.13);
  padding-top: 4px;
}
.pickupOuterDiv {
  border-radius: 5px;
  background: #89cff0;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.13);
  padding-top: 4px;
}
.scheduledInnerDiv {
  border-radius: 5px;
  background: #fff;
  padding: 20px 0px 20px 30px;
  text-align: left;
  color: #000;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 104.762% */
  letter-spacing: -0.408px;
}

.arrivedOuterDiv {
  background: orange;
}
.completedOuterDiv {
  background: #00a36c;
}
.pickupOuterDiv {
  background: #f6c4f8;
}

.patientDropdown {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ant-select .ant-select-selection-item {
  font-weight: 500 !important;
}

.patientDropdownInner {
  border-radius: 5px;
  height: 47px;
  font-size: 16px;
  z-index: 1;
  font-family: "Raleway", sans-serif;
}

.inactivePatientsFlex {
  width: auto;
}

.InactiveCheckContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.scheduleButton button {
  width: 100%;
  height: 41px;
  font-family: "Raleway", sans-serif;
  font-weight: 600px;
  margin-right: 2px;
}

.InactiveCheck {
  height: 19px;
  width: 17.57px;
}

.inactivePatientsText {
  color: #6a6a6a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.tablemiddletopsec {
  display: flex;
  justify-content: space-between;
  gap: 1vw;
  width: 100%;
  align-items: center;
  padding-bottom: 2rem;
}

.tableflextopheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkboxtabletext {
  color: #313131;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px; /* 0% */
  letter-spacing: -0.408px;
  font-family: "Raleway", sans-serif;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  /* height: 120px !important; */
}

.headingContainer {
  flex-basis: 30%; /* Adjust the width as needed */
}

.dateTodayContainer {
  flex-basis: 40%; /* Adjust the width as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
}

.calendarContainer {
  width: fit-content;
}

/* Ensuring calendar overlaps without pushing content */

.dragableCardsContainerScroll {
  display: flex; /* Using flexbox layout */
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 10px; /* Space between cards */
  height: auto;
  overflow-y: auto; /* Keep vertical scroll if needed */
  padding: 10px; /* Padding around cards */
}

.dragableCardsContainerScroll::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
}

.dragableCardsContainerScroll {
  scrollbar-width: thin; /* For Firefox */
}

.filtersContainer input::placeholder {
  color: #858585;
}

/* .filtersContainer input{
  border: 1px solid #858585 !important;
} */

.activeStylingD {
  font-family: "Raleway", sans-serif !important;
  font-size: 19px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  margin-bottom: 20px;
}

.labstitleStyling {
  font-family: "Raleway", sans-serif !important;
  font-size: 27px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  color: #000000;
  margin-top: 20px;
}
