.vitalstitleStyling {
  font-family: "Raleway", sans-serif !important;
  font-size: 27px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  color: #000000;
}
.ant-select-dropdown {
  z-index: 1050; /* Use a value higher than the modal's z-index */
}
.vitalsInput {
  height: 30px !important;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding-left: 10px;
  width: 83px;
  background-color: transparent;
}

.newVitalBtn {
  font-family: "Raleway", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  align-self: flex-end !important;
  color: #1c82f5;
}

.datetimeStling {
  height: 30px !important;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding-left: 10px;
  width: 83px !important;
  background-color: transparent;
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  background-color: #ffffff;
  padding: 18px !important;
}

.MuiTableCell-root {
  text-align: left !important;
  border-bottom: none !important;
  padding: 15px !important;
}

.css-1ygcj2i-MuiTableCell-root:first-child {
  border-top-left-radius: 6px !important;
}

.css-1ygcj2i-MuiTableCell-root:last-child {
  border-top-right-radius: 6px !important;
}

.css-ypiqx9-MuiDialogContent-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-radius: 6px !important;
}

.css-knqc4i-MuiDialogActions-root {
  background-color: #ffffff;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  padding-left: 0px !important;
}
