  .container {
    font-family: "Lato", sans-serif;
  }

  .button {
    @apply bg-[#111828] !py-2 !px-4 text-white !rounded-md !cursor-pointer !font-normal !text-base !shadow-sm !transition-all !ease-in-out;
    height: auto !important;
    border: none !important
  }

  .buttonBlue {
    @apply bg-[#007bff] !py-2 !px-4 text-white !rounded-md !cursor-pointer !font-normal !text-base !shadow-sm !transition-all !ease-in-out;
    height: auto !important;
    border: none !important
  }

  .button:hover {
    color: white !important;
    background-color: #3d3d3d !important;
    border: none !important;
  }

  .buttonBlue:hover {
    @apply !bg-blue-600 !text-white !border-none
  }