.roomViewHeader{
  height: 89px;
  border: 0px, 0px, 1px, 0px;
  padding: 1vw 2.25vw 1.563vw 2.25vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.roomViewHeader  h3{
  font-family: 'Raleway', sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
}

.tableContainer {
  width: 95%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Refined shadow */
  background: #ffffff;
  border-radius: 6px; /* Larger border radius */
  padding: 30px; /* More padding for a spacious look */
  overflow-x: auto; /* Ensures horizontal scroll for overflow */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for shadow */
}

.tableContainer:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Larger shadow on hover */
}

.patientButtonsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* larger minmax for bigger buttons */
  gap: 15px; /* space between buttons */
  margin-bottom: 20px; /* space below the grid */
}

.roomsSelect{
  background-color: transparent !important;
  border: 1px solid black;
  border-radius: 6px;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  border:none !important;
}













.todayViewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image: url(../../Assets/view-old-tree-lake-with-snow-covered-mountains-cloudy-day.jpg);
    background-size: cover;
    background-repeat: no-repeat;*/
    justify-content: center; 
    padding: 20px;
    /* background-color: #f5f5f5;  */
    height: 100vh; /* Full height of the viewport */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font */
  }
 

  
  .heading {
    font-size: 2rem; /* Responsive font size */
    font-weight: 600; /* Less bold than bold */
    margin-bottom: 30px; /* More space below the heading */
    color: #333; /* Dark grey color */
    text-transform: uppercase; /* Stylistic choice */
    letter-spacing: 1px; /* Space out letters */
  }
  
 
  
  .scrollableCheckboxGroup {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd; /* Add border to the checkbox group */
    border-radius: 8px; /* Match the border-radius of the container */
    padding: 10px; /* Spacing within the checkbox group */
  }
  
  .todayViewWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 90%;
    position: fixed;
    top: 10vh;
  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Consistent shadow with the tableContainer */
    background-color: #ffffff; /* Match the table container background */
    padding: 20px;
    border-radius: 12px; /* Consistency in design */
    transition: transform 0.3s ease-in-out; /* Smooth transition for any position changes */
  }
  
  .todayViewWrapper:hover {
    transform: translateY(-5px); /* Slight raise effect on hover */
  }
  
  /* Custom scrollbar for scrollable elements */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Responsive Typography */
  @media (max-width: 768px) {
    .heading {
      font-size: 1.5rem; /* Smaller font size on smaller screens */
    }
  }
  
  
  
  .patientButton {
    padding: 15px; /* larger padding for a bigger button */
    border: none;
    border-radius: 8px; /* slightly larger border radius for aesthetic */
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-size: 1em; /* adjust font size as necessary */
    color: #333; /* darker text for better contrast */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
  }
  
  .patientButton:hover {
    transform: scale(1.05); /* subtle grow effect on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* deeper shadow on hover */
  }
  
  /* Define light colors */
  .lightColors {
    --lightColor1: #f8d7da;
    --lightColor2: #d1ecf1;
    --lightColor3: #d4edda;
    --lightColor4: #fff3cd;
    --lightColor5: #fce8b2;
    --lightColor6: #dcd3ff;
  }