.card {
  font-family: 'Raleway', sans-serif;

}

.heading{
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

}

.noteBtns{
  height: 40px;
  border: 1px solid #1C82F5;
  color:#1C82F5;
  border-radius: 6px;
}

.inputBox{
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.section {
  margin-bottom: 20px;
}

.menu-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.menu-scrollbar::-webkit-scrollbar-thumb {
  background-color: blue; 
  border-radius: 10px;
}

/* For other browsers */
.menu-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: blue lightblue;
}


.custom-toolbar-button {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.fullscreen-class {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: auto;
  background-color: white; /* Adjust as needed */
}

.ImportItem{
  cursor: pointer;
  padding: 12px !important;
  border-radius: 8px;
}

.ImportItem:hover{
  background-color: #d3d3d3;
}



.treatment_notes_container {
  display: flex; 
  width: 100%;
}

.previous_treatments {
  flex: 1; /* Adjust the ratio as needed */
  overflow-y: auto; /* Allows scrolling within the component */
}

.treatment_notes_editor {
  flex: 3; /* Adjust the ratio as needed */
  overflow-y: auto; /* Optional: if you want the editor to also scroll independently */
}

.Noteloader {
  position: relative;
  width: 80px;
  height: 80px;
}

.Noteloader .outer_ring,
.Noteloader .inner_ring {
  position: absolute;
  border: 4px solid transparent;
  border-radius: 50%;
}

.Noteloader .outer_ring {
  width: 80px;
  height: 80px;
  border-top: 4px solid #3498db; /* Blue */
  border-bottom: 4px solid #3498db; /* Blue */
  animation: spin 2s linear infinite;
}

.Noteloader .inner_ring {
  top: 10px;
  left: 10px;
  width: 60px;
  height: 60px;
  border-left: 4px solid #e74c3c; /* Red */
  border-right: 4px solid #e74c3c; /* Red */
  animation: spin 2s linear infinite reverse;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* 
.undo-button {
  background-image: url('path/to/undo-icon.svg');
}

.redo-button {
  background-image: url('path/to/redo-icon.svg');
}

.increase-font-size-button {
  background-image: url('path/to/increase-font-size-icon.svg');
}

.decrease-font-size-button {
  background-image: url('path/to/decrease-font-size-icon.svg');
} */



@media print {
  iframe, .ant-modal, .ant-modal-mask, .ant-modal-wrap {
    display: none !important; /* Hide elements not part of the print content */
  }
}