.outer-supplements-container {
  position: relative;
  overflow: hidden;
  height: 50vh;
  margin-bottom: 10px;
}

.inner-supplements-container {
  overflow-y: scroll;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
/* 
.inner-supplements-container::-webkit-scrollbar {
  display: none;
} */

.outer-supplements-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
