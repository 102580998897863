.messages-container {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
  }
  
  .phone-input,
  .message-input,
  .modal-message-input {
    background-color: white;
  }
  
  .messages-send-button.ant-btn-primary,
  .messages-modal-send-button.ant-btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
  }
  
  .messages-send-button.ant-btn-primary:hover,
  .messages-modal-send-button.ant-btn-primary:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
  
  .messages-view-button.ant-btn {
    background-color: #fff;
    border-color: #d9d9d9;
  }
  
  .messages-view-button.ant-btn:hover {
    background-color: #fafafa;
    border-color: #40a9ff;
    color: #40a9ff;
  }
  
  .modal-input-container {
    margin-top: 16px;
  }
  
  .modal-message-input {
    margin-bottom: 16px;
  }


  .label{
    font-weight: 500;
  }

  .horizontal-line {
    width: 100%;      
    height: 2px;      
    background-color: gray;
    margin: 15px 0;    
    opacity: 0.2;
  }

  .submit-button:hover{
    background-color: #4297ec,
  }
  .header {
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .title {
    margin-bottom: 8px;
  }
  
  .infoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .messagesContainer {
    height: 400px;
    overflow-y: auto;
    padding: 16px 24px;
    background-color: #f5f5f5;
  }
  
  .messageItem {
    margin-bottom: 16px;
    display: flex;
  }
  
  .inbound {
    justify-content: flex-start;
  }
  
  .outbound {
    justify-content: flex-end;
  }
  
  .messageBubble {
    max-width: 70%;
    border-radius: 12px;
    padding: 10px 14px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .inbound .messageBubble {
    background-color: #ffffff;
  }
  
  .outbound .messageBubble {
    background-color: #dcf8c6;
  }
  
  .messageType {
    font-size: 0.9em;
    margin-bottom: 4px;
  }
  
  .messageText {
    font-size: 1em;
  }
  
  .timestamp {
    font-size: 0.75em;
    display: block;
    margin-top: 4px;
  }
  
  .footer {
    padding: 24px;
    border-top: 1px solid #e0e0e0;
    background-color: #ffffff;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.05);
  }
  
  .textArea {
    flex-grow: 1;
    margin-right: 12px;
    border-radius: 8px;
    resize: vertical;
    min-height: 50px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    padding: 12px;
  }
  
  .sendButton {
    height: 50px;
    align-self: flex-end;
    border-radius: 8px;
    text-transform: none;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  