.dropdown {
    width: 100%;
    margin-bottom: 5px;
  }
  
  .input {
    width: 100%;
  }
  
  .columnSelectionButton {
    margin-bottom: 16px;
  }
  
  .container {
    padding: 2rem;
    background-color: #f6f6f6;
    background-image: url(../../Assets/view-old-tree-lake-with-snow-covered-mountains-cloudy-day.jpg);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top:3rem;
  }
  
  .tableContainer {
    overflow-x: hidden;
    margin-top: 20px;
    border-radius: 5px; 
    max-width: 95vw;
  }

  .tableContainerHeader{
    margin-top: 0;
  }
  
  .input {
    width: 200px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
  }
  
  .checkbox {
    margin: 0;
  }
  
  :where(.css-dev-only-do-not-override-xu9wm8).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-xu9wm8).ant-table-wrapper
    .ant-table-thead
    > tr
    > td {
    background-color: #068aec !important;
    color: white !important;
  }
  
  
  
  .patientInfo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    border-radius: 8px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Styling for the patient select container */
  .patientSelectContainer {
    flex: 1;
    background: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-right: 10px;
  }
  
  .patientSelectContainer h3 {
    color: #333;
    margin-bottom: 15px;
  }
  
  .Select {
    width: 100%;
    margin-bottom: 10px;
  }
  
  
  .InactiveCheckContainer{
    display: inline;
    background-color: white;
    padding: 5px;
    border-radius: 15px;
    margin-left: 5px;
  }
  
  .InactiveCheck{
    margin: 0 3px;
    cursor: pointer;
  }
  
  .InactiveCheckHeading{
    display: inline; 
    cursor: default;
  }
  
  .patientDetailsContainer {
    background: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-left: 10px; /* Adjusted spacing to the left */
    flex: 1; /* To ensure it takes the remaining space next to patientSelectContainer */
    max-width: calc(50% - 20px); /* Adjusted maximum width to take half of the parent container width minus the gap */
  }
  
  .patientDetailsContainer h3 {
    color: #333;
    margin: 0;
    margin-bottom: 15px;
  }
  
  /* Styles for individual editable fields */
  .editableField {
    margin-bottom: 0; /* Remove margin-bottom to handle spacing with Row gutter */
  }
  
  .editableField strong {
    display: block;
    color: #333;
    margin-bottom: 5px;
  }
  
  .editableField input,
  .editableField .ant-picker {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .editableField input:focus,
  .editableField .ant-picker:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center; /* This ensures vertical alignment */
    gap: 10px;
  }
  
  .checkboxLabel strong {
    margin-bottom: 0; /* Remove margin bottom from the strong tag */
  }
  
  .checkboxLabel input[type="checkbox"] {
    accent-color: #007bff;
  }
  
  
  .detailsRow {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  /* Styles to ensure the checkbox is on its own line */
  .InactiveCheckContainer {
    display: block; /* or 'flex' if you want to keep the internal flex properties */
    margin-top: 10px; /* Adjust as needed */
  }
  
  @media (max-width: 768px) {
    .patientInfo {
      flex-direction: column;
    }
  
    .patientSelectContainer,
    .patientDetailsContainer {
      margin-right: 0;
      margin-bottom: 20px;
      max-width: 100%;
    }
    .detailsRow {
      flex-direction: column;
    }
  }