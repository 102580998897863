
/* CustomToast.css */
.customToast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  animation: slideIn 0.5s forwards, slideOut 0.5s forwards 2.5s;
}

.customToast.success {
  background-color: #4caf50;
  color: #fff;
}

.customToast.error {
  background-color: #f44336;
  color: #fff;
}

.customToast.warning {
  background-color: #ff9800;
  color: #fff;
}

@keyframes slideIn {
  from {
    transform: translateX(-50%) translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }
  to {
    transform: translateX(-50%) translateY(100%);
    opacity: 0;
  }
}
