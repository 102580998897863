.dropdown {
  width: 100%;
  margin-bottom: 5px;
}

.input {
  width: 100%;
}

.columnSelectionButton {
  margin-bottom: 16px;
}

.container {
  padding: 2rem;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 4rem;
}

.tableContainer {
  overflow-x: hidden;
  margin-top: 20px;
  border-radius: 5px;
  max-width: 95vw;
}

.tableContainerHeader {
  margin-top: 0;
}

.input {
  width: 200px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
}

.checkbox {
  margin: 0;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-xu9wm8).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background-color: #068aec !important;
  color: white !important;
}

.patientInfo {
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  min-width: 150px;
}

.patientNameContainer {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 40% !important;

  @media (max-width: 640px) {
    margin-top: 20px;
    width: 100% !important;
  }
}

.patientSelectContainer {
  flex: 1;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.patientSelectContainer h3 {
  color: #333;
  margin-bottom: 15px;
}

.Select {
  width: 100%;
  margin-bottom: 10px;
}

.InactiveCheckContainer {
  display: inline;
  background-color: white;
  padding: 5px;
  border-radius: 15px;
  margin-left: 5px;
}

.InactiveCheck {
  margin: 0 3px;
  cursor: pointer;
}

.InactiveCheckHeading {
  display: inline;
  cursor: default;
}

.patientDetailsContainer {
  background: #ffffff;
  border-radius: 6px;
  margin-left: 10px; /* Adjusted spacing to the left */
  flex: 1; /* To ensure it takes the remaining space next to patientSelectContainer */
  width: 60%;

  button {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    margin-top: -1% !important;
  }

  button:hover {
    background-color: #3498db;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 950px) {
    margin-left: 0px !important;
    margin-top: 20px;
    width: 100% !important;
  }
}

.treatment-view-tabs {
  text-transform: none !important;
}

.checkbox {
  margin-top: -1% !important;

  @media (max-width: 640px) {
    margin-top: 2% !important;
  }
}

.patientDetailsContainer h3 {
  color: #333;
  margin: 0;
  margin-bottom: 15px;
}

/* Styles for individual editable fields */
.editableField {
  margin-bottom: 0; /* Remove margin-bottom to handle spacing with Row gutter */
}

.editableField strong {
  display: block;
  color: #333;
  margin-bottom: 5px;
}

.editableField input,
.editableField .ant-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.editableField input:focus,
.editableField .ant-picker:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.checkboxLabel {
  display: flex;
  align-items: center; /* This ensures vertical alignment */
  gap: 10px;
}

.checkboxLabel strong {
  margin-bottom: 0; /* Remove margin bottom from the strong tag */
}

.checkboxLabel input[type="checkbox"] {
  accent-color: #007bff;
}

.detailsRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Styles to ensure the checkbox is on its own line */
.InactiveCheckContainer {
  display: block; /* or 'flex' if you want to keep the internal flex properties */
  margin-top: 10px; /* Adjust as needed */
}

@media (max-width: 768px) {
  .patientInfo {
    flex-direction: column;
  }

  .patientSelectContainer,
  .patientDetailsContainer {
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 100%;
  }
  .detailsRow {
    flex-direction: column;
  }
}
