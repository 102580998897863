.labstitleStyling {
  font-family: "Raleway", sans-serif !important;
  font-size: 27px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.labsactiveStyling {
  font-family: "Raleway", sans-serif !important;
  font-size: 19px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  margin-bottom: 20px;
}

.labsInput {
  height: 30px !important;
  background-color: #f1f1f1;
  height: 52px !important;
  border-radius: 6px !important;
  padding-left: 10px;
  width: 100% !important;
  margin-top: 15px;
  margin-bottom: 20px;
}

.css-ph057e {
  border-radius: 6px !important;
}

.css-1p823my-MuiListItem-root {
  box-shadow: 0px 4px 24px 0px #00000012 !important;
  border-radius: 6px;
  margin-right: 2px;
}

.css-ph057e .MuiListItemText-primary {
  font-family: "Raleway", sans-serif !important;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
}

.matching-row {
  background-color: #eebbfe;
}

.order-row {
  background-color: #ffd57f;
}

.other-row {
  background-color: #ffffff;
}

.outer-labs-container {
  position: relative;
  overflow: hidden;
  height: 50vh;
  margin-bottom: 10px;
}

.inner-labs-container {
  overflow-y: scroll;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  gap: 10px;
}

/* .inner-labs-container::-webkit-scrollbar {
  display: none;
} */

.outer-labs-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
