
.ant-select-selector{
    background-color: #F2F2F2 !important;
    border-radius: 6px;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    border:none !important;
}

.ant-select-selection-item{
    align-content: center !important;
    font-weight: 600 !important;

}