@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-table-cell {
  border-right: 1px solid #f0f0f0 !important;
  border-top: 1px solid #f0f0f0 !important;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-table-wrapper
  .ant-table-thead
  > tr
  > th {
  background-color: rgb(233 233 233) !important;
}

.patientInfo h3 {
  margin-top: 2%;
}

.nsm7Bb-HzV7m-LgbsSe.MFS4be-v3pZbf-Ia7Qfc {
  margin-top: 20px !important;
  width: 100% !important;
}

.S9gUrf-YoZ4jf {
  margin-top: 20px !important ;
  display: flex;
  justify-content: center;
  width: 100% !important;
}

.scrollable-table-body .ant-table-body {
  overflow: scroll scroll;
  max-height: 45vh;
}

.scrollable-table-body .ant-table-cell {
  min-width: 95px;
}

.ant-table-thead > tr > th {
  padding-top: 8px;
  padding-bottom: 8px;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-table-wrapper table {
  max-height: 40vh !important;
  overflow-y: auto;
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Style the scrollbar when it's in a hover state */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Change color on hover if desired */
}

.MuiTableRow-head {
  background: #eeeeee;
}

.css-11mvf7c-MuiTableCell-root {
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  font-weight: 600;
  font-size: 0.915rem;
  background-color: #eeeeee !important;
}

.Auditview {
  background-color: white;
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  max-width: 900px;
}

.css-dmmspl-MuiFormGroup-root {
  padding: 16px 24px;
  gap: 1vw;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: unset !important;
  border: 1px solid #e2e2e2;
  border-radius: 5px !important;
}

.ant-picker-dropdown {
  z-index: 2000; /* Adjust this value as needed to be higher than the modal's z-index */
}

.ant-picker-calendar
  .ant-picker-calendar-header
  .ant-picker-calendar-mode-switch {
  display: none;
}

/* .daysViewTableScroll .ant-table-body {
  overflow: scroll scroll;
  max-height: 50vh;
}

.daysViewTableScroll .ant-table-cell {
  min-width: 95px; 
}

.daysViewTableScroll .ant-table-content .ant-table-tbody::-webkit-scrollbar {
  width: 5px;
}

.daysViewTableScroll .ant-table-content .ant-table-tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.daysViewTableScroll .ant-table-content .ant-table-tbody::-webkit-scrollbar-thumb {
  background: #888;
}

.daysViewTableScroll .ant-table-content .ant-table-tbody::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: "8";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="9px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: "9";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="11px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  content: "11";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: "48";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="72px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="72px"]::before {
  content: "72";
}

.MaterialTableHeaders .MuiTableCell-root {
  padding: 5px 10px !important;
}

.MaterialTableSubHeaders .MuiTableCell-root {
  height: 30px;
  padding: 5px 10px !important;
}

.MaterialTableSubHeaderCheckBox > span {
  padding: 0;
}

.my-treatments-table .MuiTableHead-root {
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure the headers stay on top of the content */
}

/* Adjusting scrollbar */
.my-treatments-table-container .MuiTableContainer-root {
  max-height: 650px; /* Adjust this as needed */
  overflow: auto;
}

.custom-search .ant-btn {
  background-color: blue;
}

.customRoomTooltip {
  background-color: white;
}

.customWidth {
  width: 12px !important;
}

.react-contextmenu {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.react-contextmenu-item {
  padding: 8px 16px;
  cursor: pointer;
}

.react-contextmenu-item:hover {
  background-color: #f9f9f9;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background-color: rgba(173, 216, 230, 0.5) !important;
}
