.patientButton {
  background-color: #f0f4f7;
  border: none;
  font-size: 14px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px !important;
  margin-right: 10px;
  color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  font-weight: bold;
  white-space: break-spaces;
  padding: 10px 15px;
  margin: 0px 0px 8px 0px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.patientButton:hover {
  color: #000000;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-10px);
}
