.audit-view {
  padding: 2rem 3rem 0 2rem;
  background-color: #f8f9fa;
  font-family: "Raleway", sans-serif;
  height: fit-content;
  width: calc(100% - 60px);
}

.patient-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;
  background-color: #ffffff;
  /* Light gray background */
  padding: 15px;
  border-radius: 8px; /* Rounded corners */
  justify-content: center; /* Center buttons horizontally */
  align-items: center; /* Align buttons vertically */
  box-shadow: 0px 4px 22px 0px #00000024;
}

.patient-button {
  background-color: #f0f4f7; /* Default light color */
  border: none;
  /* height: 41px; */
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px !important;
  margin-right: 10px;
  color: #333; /* Dark text for better contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  font-weight: bold; /* Make text bold */
  white-space: break-spaces;
}

.patient-button:hover {
  background-color: #e2e6ea; /* Slightly darker background on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.patient-action-container {
  margin-bottom: 1rem;
}

.patient-action-container h3 {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  margin-top: 1.927vw;
  margin-bottom: 0.99vw;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.patient-buttons {
  flex-direction: column-reverse;
  display: flex;
  gap: 10px;
}

.tables-container {
  width: 100%;
  display: flex;

  gap: 2rem;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.selected-labs-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* max-width: 400px; */
  width: 100%;
}

.selected-labs-title {
  font-weight: bold;
  color: #333;
  margin-right: 10px;
  font-size: 18px;
}

.selected-labs-content {
  color: #555;
  background-color: #fff;
  border-radius: 4px;
}

.display-flex {
  display: flex;
  flex-direction: column;
}

.tubeInfoContainer {
  max-width: 600px;
  margin: auto;
}

.tubeInfoItem {
  color: white;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tubeInfoItem span {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.vitals-container {
  background-color: #fff;
  font-family: "Raleway", sans-serif;
  padding: 20px;
  border-radius: 6px;
  font-size: 13px;
  box-shadow: 0px 4px 22px 0px #00000024;
  overflow-x: auto; /* for responsiveness */
}

.vitals-container h3 {
  margin: 0;
  margin-top: 5px;
  margin-bottom: 15px;
  color: #333;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.vitals-container .vitals-table {
  width: 100%;
  border-collapse: collapse;
}

.vitals-table th,
.vitals-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid white;
}

.vitals-table th {
  background-color: #f4f4f4;
  color: #333;
}

.vitals-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.vitals-table tr:hover {
  background-color: #f1f1f1;
}

.selected-patient-content,
.patient-info {
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.selected_patient_contentTextPrep {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.inputPatient-infoPrep {
  width: 20%;
}

.treatmentView-Table-prep {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.14);
  padding: 0.99vw 1.563vw;
  overflow-x: scroll;
}

.table-flex-prepview {
  display: flex;
  /* gap: 1.5vw; */
  margin-top: 2vw;
  margin-bottom: 15px;
}
.table-proced-dispense-prepFlex {
  display: flex;
  gap: 1vw;
  align-items: baseline;
}

.action-buttons-container {
  gap: 0.5vw;
}
.vitals-labs-flex-prep,
.tables-treatments-prep {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.ant-select .ant-select-clear {
  background: #f2f2f2 !important;
}

#root
  > div
  > div.content-container
  > div
  > div.content-container.navbar-closed
  > div
  > div:nth-child(1)
  > div.table-flex-prepview
  > div.tables-treatments-prep
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > div.ant-table-wrapper.audit-table.css-dev-only-do-not-override-17sses9
  > div {
  width: max-content;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 15px 12px;
}
.fixed-height {
  max-height: 200px;
  min-height: 200px;
}
