.AdminPortal-body h1,
.AdminPortal-body h2,
.AdminPortal-body h3 {
  font-family: "Open Sans", sans-serif;
  margin: 0px;
}
.AdminPortal-body {
  margin-top: 50px;
  padding: 1.563vw 6.25vw 1.563vw 6.25vw;
}
.AdminPortal-body h1 {
  font-size: 1.667vw;
}

.UpdationBlock {
  backdrop-filter: blur(19.5px);
  background-color: #F3F3F3;
  margin-bottom: 5px;
  height: 132px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.UpdationBlock .colorBox{
  height: 100%;
  width:20px;
}

.UpdationBlock .blockTwo{
  font-family: 'Raleway', sans-serif;
  font-size: 23px;
  font-weight: 800;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color:black

}

.patient-view-flexBox {
  display: flex;
  gap: 1vw;
  height: 41px;
}

.UpdationBlock h2 {
  padding: 1.521vw 2.963vw 1.521vw 1.463vw;
  border-radius: 8px 8px 0px 0px;
}

.AdminPortal-body {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

/* Dropdown CSS antd */
:where(.css-dev-only-do-not-override-qgg3xn).ant-space-gap-col-small {
  border: 1px solid rgb(186, 186, 186);
  padding: 0.3vw 0.8vw;
  width: 30%;
  padding-left: 0.8vw;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 1vw;
}

.status-dropdown
  :where(.css-dev-only-do-not-override-qgg3xn).ant-space-gap-col-small {
  margin-bottom: 1vw;
}

.PatientDetails {
  display: flex;
  margin-top: 1vw;
  gap: 1vw;
}

.noteBlock {
  margin-top: 1vw;
}
:where(.css-dev-only-do-not-override-qgg3xn)[class^="ant-form"]
  [class^="ant-form"],
:where(.css-dev-only-do-not-override-qgg3xn)[class*=" ant-form"]
  [class^="ant-form"],
:where(.css-dev-only-do-not-override-qgg3xn)[class^="ant-form"]
  [class*=" ant-form"],
:where(.css-dev-only-do-not-override-qgg3xn)[class*=" ant-form"]
  [class*=" ant-form"] {
    margin-top: 0px;
    margin-bottom: 0px;;
    width: 100%;
}


.addprocedure-form{
    display: flex;
    flex-direction: column;
    gap: 1vw;;
    margin-top: 1vw;;
    margin-bottom: 2vw;;
}

.removeDosage{
    display: flex;
    margin-top: 1vw;;
}

.labsBody{
    margin: 2vw 0vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;;
}




.dosage-container {
  margin-top: 10px;
}

.dosage-list {
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 10px;
}

.dosage-field{
  margin-bottom: 5px;
}


.shortcut-section {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap as needed */
}

.shortcut-edit-icon {
  margin-left: 10px; /* Adjust the margin as needed */
}


.custom-subcategory-section {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust gap as needed */
  margin: 10px 0; /* Margin for spacing */
}

/* Disabled Button Style */
.ant-btn-disabled, .ant-btn-disabled:hover, .ant-btn-disabled:focus, .ant-btn-disabled:active {
  background-color: #f5f5f5;
  color: #d9d9d9;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

/* Additional gaps and layout adjustments */
.section-gap {
  margin-top: 20px; /* Gap between sections */
}

p{
  margin-bottom: 0 !important;
}