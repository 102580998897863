.container {
  font-family: "Lato", sans-serif;
}

.button {
  @apply !bg-[#007bff] text-white rounded-md cursor-pointer font-normal text-base shadow-sm transition-all ease-in-out;

  height: auto;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  /* padding-top: 15px;
  padding: 15px;
  padding: 15px;
  padding: 15px; */
}

.button:hover {
  color: white !important;
  background-color: #1976d2 !important;
}
