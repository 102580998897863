.signup_container {
    display: flex; 
    height: 100vh;
    width: 100vw;
  }
   
  .signup_box {
    box-sizing: border-box;
    width: 100%;
    padding: 25px;
    height: auto;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  }
  
  .logo_section {
    display: flex;
    justify-content: center;
    height: 65px;
    margin-bottom: 15px;
    margin-top: 0;
    width: 100%;
  }
  
  .logo_image {
    height: 65px;
  }
  
  .signup_header {
    font-size: 26px;
    color: #000000;
    margin-bottom: 35px;
    text-align: center;
    font-weight: 600;
  }
  
  .input_label {
    font-size: 17px;
    color: #000000;
    margin-top: 12px;
  }
  
  .input_field {
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    margin-top: 6px;
    border: 1px solid #f0f5fd;
    border-radius: 6px;
    background-color: #F4F7FE;
    color: #000000;
    font-size: 15px;
  }
  
  .signup_button {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 12px;
    margin-top: 25px;
    background-color: #0099CC;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .signup_button:hover {
    background-color: #036d90;
  }
  
  .existing_account {
    margin-top: 22px;
    text-align: center;
    font-size: 15px;
    color: #000000;
  }
  
  .login_redirect {
    color: #0099CC;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .login_redirect:hover {
    color: #036d90;
  }
  
  
  
  .input_wrapper {
    position: relative;
    width: 100%;
  }
  
  .eye_icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #AAA; /* Or any suitable color */
  }
  
  .error_message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
  }

  .logo_section{
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
  }