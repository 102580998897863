.dispensedtitleStyling {
  font-family: "Raleway", sans-serif !important;
  font-size: 27px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  color: #000000;
  margin-top: 20px;
}

.dispensedDropdownInput {
  height: 30px !important;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding-left: 10px;
  width: 150px;
  background-color: transparent;
}

.searchInput {
  background-color: #f1f1f1;
  height: 52px !important;
  border-radius: 6px !important;
  width: 100% !important;
}

.dispensedNumberInput {
  height: 30px !important;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding-left: 10px;
  width: 83px;
  background-color: transparent;
}

.dispensedTextInput {
  /* Styles for the text input */
  height: 30px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding-left: 10px;
  background-color: transparent;
}

.dispensedTextInputHere {
  width: 100%;
  height: 30px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding-left: 10px;
  background-color: transparent;
}

.divTable {
  display: flex;
  flex-direction: column;
}

.divTableHead,
.divTableBody {
  width: 100%;
  padding-left: 10px;
}

.MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root {
  background-color: white !important;
}

.itemName {
  flex-grow: 1; /* Adjust this for the first column */
}

.contentFitfourC {
  display: flex;
  justify-content: flex-end; /* Aligns content to the right */
  padding-left: 10px; /* Adds space between columns */
  width: 150px; /* Fit to content */
}
.customTooltip {
  font-size: 26px;
}
.contentFitthreeC {
  display: flex;
  justify-content: center; /* Aligns content to the right */
  padding-left: 10px; /* Adds space between columns */
  width: 150px; /* Fit to content */
}

.alignRight {
  flex: 1;
  display: flex;
  justify-content: flex-end; /* Aligns content to the right */
  padding-left: 10px; /* Adds space between columns */
}

.divTableRow {
  min-height: 53px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  box-shadow: 0px 4px 24px 0px #00000012;
  border: none;
  border-radius: 6px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.divTableCell {
  flex: 1; /* Adjust this value as needed for your layout */
  padding: 8px; /* Add padding for better spacing */
}

.css-knqc4i-MuiDialogActions-root {
  padding-right: 0px !important;
}

.searchInput {
  height: 50px !important;
  background-color: #f1f1f1;
  height: 52px !important;
  border-radius: 6px !important;
  padding-left: 10px;
  width: 100% !important;
  margin-bottom: 20px;
  border: none;
}

.activeStylingD {
  font-family: "Raleway", sans-serif !important;
  font-size: 19px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  margin-bottom: 20px;
}

.css-ypiqx9-MuiDialogContent-root::-webkit-scrollbar {
  display: none !important; /* For Chrome, Safari, and Opera */
}

.outer-dispensed-container {
  position: relative;
  overflow: hidden;
  height: 50vh;
  margin-bottom: 10px;
}

.inner-dispensed-container {
  overflow-y: scroll;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

/* .inner-dispensed-container::-webkit-scrollbar {
  display: none;
} */

.outer-dispensed-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
